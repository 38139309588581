<nav class="navbar navbar-expand-lg py-3 navbar-light fixed-bottom">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mx-auto">
      <li class="nav-item"><a class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }"
          routerLink="/">{{homeTitle}}</a></li>
      <li class="nav-item"><a class="nav-link" skipLocationChange="true" routerLinkActive="active"
          routerLink="/likes">{{likesTitle}}</a></li>
      <li class="nav-item"><a class="nav-link" skipLocationChange="true" routerLinkActive="active"
          routerLink="/house">{{houseTitle}}</a></li>
      <li class="nav-item"><a class="nav-link" skipLocationChange="true" routerLinkActive="active"
          routerLink="/stuff">{{stuffTitle}}</a></li>
      <li class="nav-item"><a class="nav-link" skipLocationChange="true" routerLinkActive="active"
          routerLink="/myself">{{myselfTitle}}</a></li>
    </ul>

  </div>
</nav>
<nav class="d-flex fixed-top">
  <div class="navbar" id="navbarSupportedContent">
    <ul class="navbar-nav mx-auto">
      <li class="m-1">
        <a href="https://www.etsy.com/ie/shop/WhatmoreDesign" target="_blank">
          <img class="icon clicker etsy" src="assets/images/etsy.png" />
          <span class="iconText etsy">stationary & paintings</span>
        </a>
      </li>
      <li class="m-1">
        <a href="https://www.instagram.com/tawny.whatmore/" target="_blank">
          <img class="icon clicker instagram" src="assets/images/instagram.webp" />
          <span class="iconText instagram">uhh grossss</span>
        </a>
      </li>
    </ul>
  </div>
</nav>