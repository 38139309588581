import { Component, OnInit } from '@angular/core';
declare var $ : any

@Component({
  selector: 'app-stuff',
  templateUrl: './stuff.component.html',
  styleUrls: ['./stuff.component.scss']
})
export class StuffComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }
  unfilterCards() {
    var cards, cardContainer, buttons, buttonContainer, i;

    //filtering cards
    cardContainer = document.getElementById("stuffItems");
    cards = cardContainer.getElementsByClassName("card");
    for (i = 0; i < cards.length; i++) {
        var card = cards[i];
          card.style.display = "";
    }

    //setting active to current filter button
    buttonContainer = document.getElementById("stuffFilters")
    buttons = buttonContainer.getElementsByClassName("btn");
    for (var j = 0; j < buttons.length; j++) {
      var button = buttons[j];
      button.classList.remove("active");
      }
    }

  filterCards(category) {
    var cards, cardContainer, buttons, buttonContainer, i;

    //filtering cards
    cardContainer = document.getElementById("stuffItems");
    cards = cardContainer.getElementsByClassName("card");
    for (i = 0; i < cards.length; i++) {
        var card = cards[i];
        if (card.classList.contains(category)) {
          card.style.display = "";
        } else {
          card.style.display = "none";
        }
    }

    //setting active to current filter button
    buttonContainer = document.getElementById("stuffFilters")
    buttons = buttonContainer.getElementsByClassName("btn");
    for (var j = 0; j < buttons.length; j++) {
      var button = buttons[j];
      if(button.id == ("btn" + category)) {
        button.classList.add("active");
      } else {
        button.classList.remove("active");
      }
    }
}

}
