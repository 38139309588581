import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LikesComponent } from './likes/likes.component';
import { HouseComponent } from './house/house.component';
import { StuffComponent } from './stuff/stuff.component';
import { MyselfComponent } from './myself/myself.component';


const routes: Routes = [
  {path: '', component: HomeComponent, data: {animation: 'home'}},
  {path: 'likes', component: LikesComponent, data: {animation: 'likes'}},
  {path: 'house', component: HouseComponent, data: {animation: 'house'}},
  {path: 'stuff', component: StuffComponent, data: {animation: 'stuff'}},
  {path: 'myself', component: MyselfComponent, data: {animation: 'myself'}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes),
    BrowserModule,
    BrowserAnimationsModule],
  exports: [RouterModule]
})
export class AppRoutingModule { } 
