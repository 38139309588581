<div class="container p-3">
    <div class="row">
        <div class="col-lg-12">
            <h1>stuff i've done</h1>
            <span id="portfolioButton" onclick="window.open('http:\/\/sarcodius.com\/portfolio\/', '_blank')"
                class="icon clicker"> </span>
            <span id="portfolioHint" class="iconText">more from college days</span>
        </div>
    </div>
    <div class="row mt-3">
        <div id="stuffFilters" class="col-lg-12 text-center">
            <button id="btnall" type="button" (click)="unfilterCards()" class="btn btn-outline-secondary mx-1">All</button>
            <button id="btnart" type="button" (click)="filterCards('art')" class="btn btn-outline-secondary mx-1">Painting/Drawing</button>
            <button id="btndesign" type="button" (click)="filterCards('design')" class="btn btn-outline-secondary mx-1">Graphic Design</button>
            <button id="btnother" type="button" (click)="filterCards('other')" class="btn btn-outline-secondary mx-1">Other</button>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col-lg-12">
            <div id="stuffItems" class="card-columns">
                <div class="card art">
                    <img src="assets/images/portfolio/eye.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">eye - watercolour</h5>
                    </div>
                </div>
                <div class="card art">
                    <img src="assets/images/portfolio/poets.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">poets of the fall - watercolour</h5>
                    </div>
                </div>
                <div class="card art">
                    <img src="assets/images/portfolio/marley.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">doggo marley - pencil</h5>
                    </div>
                </div>
                <div class="card art">
                    <div id="christmascarousel" class="carousel slide card-img-top" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img class="d-block w-100" src="assets/images/portfolio/christmas1.jpg"
                                    alt="First slide">
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="assets/images/portfolio/christmas2.jpg"
                                    alt="Second slide">
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="assets/images/portfolio/christmas3.jpg"
                                    alt="...">
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="assets/images/portfolio/christmas4.jpg"
                                    alt="...">
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="assets/images/portfolio/christmas5.jpg"
                                    alt="...">
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#christmascarousel" role="button"
                            data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#christmascarousel" role="button"
                            data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>

                    <div class="card-body">
                        <h5 class="card-title">christmas cards</h5>
                    </div>
                </div>
                <div class="card art">
                    <img src="assets/images/portfolio/friends.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">friends engagement - pencil</h5>
                    </div>
                </div>
                <div class="card art">
                    <img src="assets/images/portfolio/statue.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">statue - watercolour</h5>
                    </div>
                </div>
                <div class="card art">
                    <img src="assets/images/portfolio/cluckles.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">cluckles - watercolour</h5>
                    </div>
                </div>
                <div class="card art">
                    <img src="assets/images/portfolio/marko.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">marko - poets of the fall - pencil</h5>
                    </div>
                </div>
                <div class="card art">
                    <img src="assets/images/portfolio/beach.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">beach - watercolour</h5>
                    </div>
                </div>
                <div class="card art">
                    <img src="assets/images/portfolio/dadan.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">the dragon one from game of thrones - pencil</h5>
                    </div>
                </div>
                <div class="card art">
                    <img src="assets/images/portfolio/lilies.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">water lilies - acrylic</h5>
                    </div>
                </div>
                <div class="card other">
                    <img src="assets/images/portfolio/cake1.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">cake</h5>
                    </div>
                </div>
                <div class="card design">
                    <div id="weddingcarousel" class="carousel slide card-img-top" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img class="d-block w-100" src="assets/images/portfolio/weddinginvite.jpg"
                                    alt="First slide">
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="assets/images/portfolio/weddinginvite2.jpg"
                                    alt="Second slide">
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="assets/images/portfolio/weddingrsvp.jpg"
                                    alt="...">
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="assets/images/portfolio/weddingrsvp2.jpg"
                                    alt="...">
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="assets/images/portfolio/weddingsigningposter.jpg"
                                    alt="...">
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="assets/images/portfolio/weddingtimetable.jpg"
                                    alt="...">
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="assets/images/portfolio/weddingtimetable2.jpg"
                                    alt="...">
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#weddingcarousel" role="button"
                            data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#weddingcarousel" role="button"
                            data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">wedding stationary</h5>
                    </div>
                </div>
                <div class="card design">
                    <div id="engagementcarousel" class="carousel slide card-img-top" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img class="d-block w-100" src="assets/images/portfolio/engagement1.png"
                                    alt="First slide">
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="assets/images/portfolio/engagement2.png"
                                    alt="Second slide">
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="assets/images/portfolio/engagement3.png"
                                    alt="...">
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="assets/images/portfolio/engagement4.png"
                                    alt="...">
                            </div>
                            <div class="carousel-item">
                                <img class="d-block w-100" src="assets/images/portfolio/engagement5.png"
                                    alt="...">
                            </div>

                        </div>
                        <a class="carousel-control-prev" href="#engagementcarousel" role="button"
                            data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#engagementcarousel" role="button"
                            data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">engagement illustrations</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>