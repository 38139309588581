<header>


    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3">
                <img id="logo-img" class="img-fluid" src="assets/images/whatmoreLogo.png" />
            </div>
        </div>
        <div class="row mt-lg-5 text-center">
            <h1 class="col-lg-12" id="mahName">Tawny Whatmore</h1>
        </div>

        <div id="randomName" class="row">
            <div class="col-lg-12 text-center">
                <span (click)="randomName()" id="nameHelp" class="icon clicker"> </span>
                <span id="nameHint" class="iconText">other names i respond to</span>
            </div>
        </div>
    </div>
</header>