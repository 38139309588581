<div class="container d-flex align-items-center p-3">
    <div class="row">
        <div class="col-lg-6">
            <h2>the house</h2>
            <img class="img-fluid" src="assets/images/house/house160517.jpeg" alt="House, 16 May 2017"/>
            <p>bought my first house in May 2017. 1970s prefab bungalow, 12m x 6m, on 0.4 acres. Came with garage, shed, and broken greenhouse. Needed some renovations.</p>
            <p>because of it's construction the walls are really thin, meaning hardly any insulation. The windows were timber framed single glazing. The heating system installed when we arrived was a combination of electric storage heaters and an oil boiler, that fed the old kitchen (now knocked down), the sitting room and the now kitchen. all replaced by the following: </p>
            <ul>
                <li>approx 20cm attic insulation</li>
                <li>new pvc double glazed windows throughout</li>
                <li>external insulation installed by <a href="http://www.ecobuild.ie/" target="_blank">RMC EcoBuild</a></li>
                <li>new central heating system fed by a stove with a back boiler</li>
            </ul>
            <p>the kitchen extention was a bit problematic. low ceiling and a large crack in the wall. so we knocked it down to leave only the original bungalow.</p>
            <p>there were some odd built-in cupboards, that took up a lot of space making smaller rooms. we got rid of these and rebuilt the walls which made the bedrooms slighly bigger and more square.</p>
            <p>new kitchen made from pallet wood with electric underfloor heating.</p>
            <p>general aesthetical renovations like painting, new skirting and trim, new tiled and laminate flooring, etc.. other fun things we made:</p>
            <ul>
                <li>custom storage with window seat and bookshelf. framed with CLS timber, doors made from scrap wood/pallet wood</li>
                <li>breakfast bar area. covered up a doorway that would have been leading into the kitchen, knocked a hole through the wall and made a pallet wood table that connects the dining area to the kitchen.</li>                
            </ul>
            <p>video tours of the property as renovations progressed:</p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PLAzrHvOD-NjI5csEzLgPh0yndOgYsQgGE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="col-lg-6">
            <h2>ongoing projects</h2>
            <ul>
                <li>rebuilding/rendering garden walls</li>
                <li>renovating garage</li>
                    <ul>
                        <li>Replacing rotten roof timbers</li>
                        <li>refelting and retiling roof</li>
                        <li>dry lining, insulating and plastering walls</li>
                        <li>fitting a double and a single pvc door</li>
                        <li>tiling utility section of garage</li>
                        <li>removing pebbledash and rerender a smooth finish</li>
                        <li>new fascias and soffits</li>
                    </ul>
                <li>building small art shed, approx 3m x 3.5m</li>
                    <ul>
                        <li>timber framed shed with timber cladding (on plinth style foundations</li>
                        <li>green roof, most likely using sedem</li>
                        <li>dry lining/insulating</li>
                        <li>fitting pvc window and door</li>
                        <li>water storage system that feeds small sink/watering can</li>
                        <li>possible solar panel to run lights</li>
                    </ul>
                <li>maturing garden</li>
                    <ul>
                        <li>expanding vegetable patch to 12m x 7m using no dig bed methods</li>
                        <li>building fence around vegetable patch</li>
                        <li>building coldframes</li>
                        <li>expanding greenhouse by another 3m x 2m</li>
                        <li>building pergola to protect fruit bushes</li>
                        <li>creating lots of border edged paths, evenually reducing grass area in back garden</li>
                        <li>building new compost heap, retiring old one</li>
                        <li>creating several ponds of small/medium size</li>
                    </ul>
                <li>rebuilding hot house</li>
                    <ul>
                        <li>block half wall</li>
                        <li>timber framing with insulation</li>
                        <li>custom sliding door</li>
                        <li>lots of shelving</li>
                        <li>water storage system to feed sink/watering can</li>
                    </ul>
                    <li>yet to do inside the house</li>
                    <ul>
                        <li>new pvc front door for porch with adjacent shoe rack</li>
                        <li>decide what to do with walls - plaster or line.. </li>
                        <li>fix cracks in ceiling and paint</li>
                        <li>coving, with possible led lights</li>
                        <li>make good some tiles/laminate flooring that are shy of new door saddles</li>
                        <li>move electric boards outside if possible, then finish the decorating around that area</li>
                        <li>frame out some space in the attic for a proper storage area</li>
                    </ul>
            </ul>
        </div>
    </div>
</div>