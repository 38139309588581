import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { HomeComponent } from './home/home.component';
import { LikesComponent } from './likes/likes.component';
import { HouseComponent } from './house/house.component';
import { MyselfComponent } from './myself/myself.component';
import { StuffComponent } from './stuff/stuff.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    LikesComponent,
    HouseComponent,
    MyselfComponent,
    StuffComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
