<div class="container d-flex align-items-center">
    <div class="row">
        <div class="col-lg-6">
            <h2>stuff i like doing</h2>
            <ul>
                <li>gardening</li>
                <li>diying/renovating</li>
                <li>baking</li>
                <li>petting animals</li>
                <li>making little apps that make life easier</li>
                <ul>
                    <li>time tracking app for invoicing (still developing)</li>
                    <li>scheduled weather app to help with gardening (still in brain)</li>
                    <li>note taking app for gardening(still in brain)</li>
                    <li>alarm app that works for my routine(still in brain)</li>
                    <li>app to help draw reference images for paintings</li>
                </ul>
            </ul>
        </div>
        <div class="col-lg-6">
            <h2>stuff i like doing</h2>
            <h4>(but dont do enough of)</h4>
            <ul>
                <li>drawing and painting</li>
                <li>animation</li>
                <li>playing guitar/bass</li>
                <li>playing games</li>
            </ul>

            <h4>stuff i'd like to know how to do</h4>
            <ul>
                <li>photography/videography</li>
                <li>3d modelling</li>
                <li>scaled drawings for buildings</li>
                <li>pottery</li>
                <li>wood carving</li>
                <li>sewing</li>
            </ul>
        </div>
    </div>
</div>