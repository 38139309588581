import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
      var hintSize = document.getElementById("nameHint").getBoundingClientRect();
      console.log(hintSize);
      document.getElementById('randomName').style.marginLeft = String(Math.round(hintSize.width) + "px");
  }

  randomName() {
    var view = document.getElementById("mahName");
    const firstNames = ["Tawny", "Tawmy", "Twany", "Tammy", "Tanya", "Tway", "Tawy", "Tony", "Tanny", "Tina", "El Diablo"];
    const secondNames = ["Whatmore", "Wagmore", "Whatless", "Whatnot", "Whatsquat"];

    var chosenFirst = firstNames[Math.floor(Math.random() * firstNames.length)];
    var chosenSecond = secondNames[Math.floor(Math.random() * secondNames.length)];
    view.innerHTML = chosenFirst == "El Diablo" ? chosenFirst : chosenFirst + " " + chosenSecond;
  }

  typeWriter(value, viewName){
    var i = 0;
    var speed = 1; /* The speed/duration of the effect in milliseconds */
      if (i < value.length) {
        document.getElementById(viewName).innerHTML += value.charAt(i);
        i++;
        setTimeout(this.typeWriter, speed);

  }
  }
}
