import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  homeTitle: string = "home";
  likesTitle: string = "things i like";
  houseTitle: string = "house projects";
  stuffTitle: string = "stuff ive done";
  myselfTitle: string = "golly do i have to talk about myself";
  

  constructor() { }

  ngOnInit(): void {
    
  }

}
