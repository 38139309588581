<div class="container d-flex align-items-center">
    <div class="row">
        <div class="col-lg-12">
            <h1>talking about myself</h1>
            <h4>(ugh dont like this bit)</h4>
                <span id="cvButton" onclick="window.open('/assets/images/cv2020.pdf', '_blank')" class="icon clicker"> </span>
                <span id="cvHint" class="iconText">all business like 😎</span>
            <p class="mt-5">right... was born october 1996, youngest of 5 and the only girl. all of my older brothers are engineers of some shape or form, and when i was younger i thought geeeeee whizz ill never be an engineer, letting down the non-existant family tradition. low and behold i managed to blag the title through software, i'm part of the family now bruh. 
            <p>i started off my working days as a graphic designer, but over the years wanting to do something new, i started waving maniacally at the little green android robot. </p>
            <p>so now i play with android apps, and its fun and challenging. but i wont forever if i get my way. because i want to build a castle, obvs.</p>
            <p>'insane, impossible!', i hear you say, but nah not really. now maybe im just an optimist, but a castle is just a big box with nice decorations on it, so look out respective planning committess, i'll be coming to for you.</p>
            <p>a castle though is only part of my end goal. i'm gonna need land, and a modest heap of it. at first 2-4 acres seemed reasonable. but for now, a minimum of 10 acres is more appropriate.</p>
            <p>and why do i want land? i seem to want a lot of things dont i. well, i have a few ideas.</p>
            <ul>
                <li>i will of course have lots of animal friends around the place. i'd also like to make a home for old animals. the idea being that any old timers at shelters aren't likely to get adopted, so i'd invite them over to my castle and they can live out the their days comfortably, with space to wander, or space to sleep.</li>
                <li>creating a habitat for wildlife to thrive - i need to learn more on this, but it's something i'd like to achieve.</li>
                <li>secultion - yes alright, im a loner, i like quiet time, but some wonderful brain activity can come from it.</li> 
            </ul>
            <p>so now you have an idea of what the craic is, you're probably thinking 'preachy, hippy, big opinions', but relax, lucky for you im <del>shy, socially awkward and will probably stutter in your face for the duration of any conversation</del> introverted.</p>
        </div>
        </div>
        </div>